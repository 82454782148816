
import { defineComponent, getCurrentInstance, reactive, ref, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useSignListData';
import useVisible from '@/hooks/useVisible';
import settlePdfView from './settlePdfPreview.vue';
import viewAuditLog from './viewAuditLog.vue';
import router from '@/router';
import { ajaxLoading, errorMessage, errorTip, successTip } from '@/utils/publicMethods';
import { httpGet, httpPost } from '@/api';
export default defineComponent({
  name: 'signList',
  components: { ErpList, settlePdfView, viewAuditLog },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const pdfDialog = reactive({
      visible: false,
      loading: true,
    });

    const ausitSignDialog = reactive({
      isShow: false,
    });

    const formData = reactive({
      isAgree: null,
      desc: '',
    });
    const data = reactive({
      currentRow: null,
      rowData: null,
      load: false,
      positionData: [], // 马力签章坐标位置  关键字 乙方（盖章）
      pdfUrl: '',
    });
    const customParams: any = ref();
    const formType = ref(1);
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };

    // 返回
    const cancel = (status = false) => {
      pdfDialog.visible = false;
      ausitSignDialog.isShow = false;
      closeVisible();
      if (status) {
        refreshTable();
      }
    };
    // 审核记录
    const toAuditPage = () => {
      const path = '/erp/financial/auditStatement';
      router.push(path);
    };
    const validAuditDialogForm = () => {
      const result = true;
      if (formData.isAgree === null || formData.isAgree === undefined) {
        errorTip('请进行审核操作');
        return false;
      }
      if (formData.isAgree === false && !formData.desc) {
        errorTip('审核不通过请说明原因');
        return false;
      }
      return result;
    };
    const getKeywordPos = async () => {
      const keyWords = [
        {
          keyType: 1,
          keyword: '开户账号',
        },
        {
          keyType: 2,
          keyword: '开票金额',
        },
      ];
      pdfDialog.visible = true;
      const { excelName, stmtPdfUrl } = data.rowData as any;
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/app/contract/keywordPos', {
        fileName: excelName,
        fileUrl: stmtPdfUrl,
        keyWords: keyWords,
      });
      ajaxLoading.unLock();
      if (res.code === 200) {
        data.positionData = res.result;

        if ((data.positionData[1] as any).positionList.length > 0) {
          (data.positionData[1] as any).positionList[0].coordinateList[0].posx = (data.positionData[1] as any).positionList[0].coordinateList[0].posx;
          (data.positionData[1] as any).positionList[0].coordinateList[0].posy = (data.positionData[1] as any).positionList[0].coordinateList[0].posy;
        }
        data.pdfUrl = (data.rowData as any).stmtPdfUrl;
        pdfDialog.visible = true;
      }
    };
    const auditDialogAPI = async () => {
      const { desc, isAgree } = formData;
      const { id, version } = data.rowData as any;
      const params = {
        auditDesc: desc,
        auditStatus: isAgree ? 3 : 2,
        id,
        version,
      };
      const res = await httpPost('/malicrm/fmSellStmt/auditStmt', params);
      if (res.code === 200) {
        if (isAgree) {
          getKeywordPos();
        }
        refreshTable(true);
      } else {
        errorTip(res.message);
      }
    };
    const auditDialogCallBack = (type) => {
      if (type === '提交') {
        if (validAuditDialogForm()) {
          // 关闭弹窗
          ausitSignDialog.isShow = false;
          auditDialogAPI();
        }
      }
      if (type === '返回') {
        // 关闭弹窗 清空formDate表单
        ausitSignDialog.isShow = false;
      }
    };
    // 确认签章
    const pdfSubmit = async (version) => {
      const { excelName, id, stmtPdfUrl } = data.rowData as any;
      const params = {
        excelName,
        id,
        keyword: '开户账号',
        posX: 90,
        posY: 0,
        stmtPdfUrl,
        uploadVos: data.positionData,
        version,
      };
      pdfDialog.loading = true;
      const res = await httpPost('/malicrm/fmSellStmt/maliSignStmt', params);
      pdfDialog.loading = false;
      data.pdfUrl = null as any;
      if (res.code === 200) {
        const title = '签章成功';
        successTip(title);
        pdfDialog.visible = false;

        refreshTable(true);
      } else {
        errorTip(res.message);
      }
    };
    // 关闭pdf操作
    const closePdf = async () => {
      data.pdfUrl = null as any;
      pdfDialog.visible = false;
      let { id } = data.rowData as any;
      //获取版本信息
      let res = await httpGet(`/malicrm/fmSellStmt/getStmtVersion/${id}`);
      let version = '';
      if (res.code === 200) {
        version = res.result;
      } else {
        errorMessage(res.message);
      }
      let params = { id, version };
      let res2 = await httpPost('/malicrm/fmSellStmt/maliCancelSignStmt', params);
      if (res2.code === 200) {
        refreshTable(true);
      } else {
        errorMessage(res2.message);
      }
    };
    // 编辑
    const auditASign = (row) => {
      data.rowData = {} as any;
      formData.isAgree = null as any;
      formData.desc = null as any;
      ausitSignDialog.isShow = true;
      data.rowData = row;
    };
    const openLogDialog = (row) => {
      data.rowData = row;
      showVisible();
    };
    return {
      closePdf,
      openLogDialog,
      pdfSubmit,
      auditDialogCallBack,
      ausitSignDialog,
      auditASign,
      pdfDialog,
      formData,
      data,
      ...toRefs(data),
      toAuditPage,
      formType,
      cancel,
      closeVisible,
      customParams,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
    };
  },
});
