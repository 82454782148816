<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">审核日志</div>
      <div class="erp-form-bar">
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <div class="settleNo">
        <span class="color-label">结算编号：</span>{{ detailData.sellStmtNo }}
      </div>
      <div class="log-item-wrap">
        <logItem :arr="detailData.recordListVos"></logItem>
      </div>
    </div>
  </div>
</template>
<script>
import logItem from './logItem.vue';
export default {
  components: {
    logItem,
  },
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      detailData: {},
    };
  },
  created() {
    this.initData(this.id);
  },
  methods: {
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    initData(id) {
      this.ajax({
        type: 'GET',
        url: `/malicrm/fmSellStmt/getFmSellStmtAuditRecordList/${id}`,
        data: {},
        success: (res) => {
          if (res.code === 200) {
            this.detailData = res.result;
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.settleNo {
  font-size: 16px;
  color: #303133;
  margin-left: 30px;
  margin-top: 20px;
}
.log-item-wrap {
  margin-left: 30px;
}
.color-label {
  font-size: 16px;
  color: #737c8c;
}
</style>
