import { ErpTableOptions } from '@/types/type';
import { showFiles } from '@/utils/publicMethods';

import { computed, defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();
  const needSign = [
    {
      label: '是',
      value: true,
    },
    {
      label: '否',
      value: false,
    },
  ];

  const columnList: ErpTableOptions[] = [
    {
      label: '结算单号',
      prop: 'sellStmtNo',
      minWidth: 128,
      value: '',
      type: 'input',
    },
    {
      label: '销售合同号',
      prop: 'businessContractNo', // 属性
      minWidth: 150,
      value: '',
      type: 'input',
    },
    {
      label: '客户',
      prop: 'customerName', // 属性
      minWidth: 182,
      value: '',
      type: 'input',
    },
    {
      label: '签约主体',
      prop: 'contractPrincipalName', // 属性
      minWidth: 182,
      value: '',
      type: 'input',
    },
    {
      label: '计费方式',
      prop: 'settleType',
      propDesc: 'settleTypeDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'settle_type',
    },
    {
      label: '结算日期',
      prop: 'settleDate',
      minWidth: 128,
      value: [],
      type: 'time',
      filterProp: ['settleStartDate', 'settleEndDate'],
    },
    {
      label: '确认人',
      prop: 'confirmOperator',
      minWidth: 114,
      value: '',
      type: 'input',
    },
    {
      label: '确认时间',
      prop: 'confirmTime',
      minWidth: 178,
      value: [],
      type: 'time',
      filterProp: ['confirmTimeStartTime', 'confirmTimeEndTime'],
    },
    {
      label: '确认说明',
      prop: 'confirmDesc',
      minWidth: 210,
      value: '',
      type: 'input',
      isSort: false,
    },
    {
      label: '审核状态',
      prop: 'auditStatus',
      propDesc: 'auditStatusDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'sell_stmt_audit_status',
    },
    {
      label: '审核人',
      prop: 'auditOperator',
      minWidth: 114,
      value: '',
      type: 'input',
    },
    {
      label: '审核时间',
      prop: 'auditTime',
      minWidth: 178,
      value: [],
      type: 'time',
      filterProp: ['auditTimeStartTime', 'auditTimeEndTime'],
    },
    {
      label: '审核说明',
      prop: 'auditDesc',
      minWidth: 220,
      value: '',
      type: 'input',
      isSort: false,
      isFilter: false,
    },
    {
      label: '是否需要客户电子签章',
      prop: 'isNeedCustomerSign',
      propDesc: 'isNeedCustomerSignDesc',
      minWidth: 216,
      value: '',
      type: 'signSelect',
      options: needSign,
    },
    {
      label: '签章状态',
      prop: 'signStatus',
      propDesc: 'signStatusDesc',
      minWidth: 160,
      value: [],
      type: 'multiSelect',
      options: 'sell_stmt_sign_status',
    },
    {
      label: '马力签章时间',
      prop: 'maliSignTime',
      minWidth: 180,
      value: [],
      type: 'time',
      filterProp: ['maliSignTimeStart', 'maliSignTimeEnd'],
    },
    {
      label: '客户签章时间',
      prop: 'customerSignTime',
      minWidth: 180,
      value: [],
      type: 'time', // 筛选类型 time 日期
      filterProp: ['customerSignTimeStart', 'customerSignTimeEnd'],
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 310,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button plain type="primary" @click='auditASign(row)' v-if="showBtn === 1 && hasPermission('erp:stmt:audit')">审核并签章</el-button>
        <el-popover placement="bottom-start" width="110" trigger="click" v-if="(showBtn === 1 || showBtn === 2 || showBtn === 3) && hasPermission('erp:stmtAudit:list')"
          popper-class="indical-popover">
          <div class="operate-indical operate-audit-po" @click="onlineView(row,'excel')">
            结算单excel</div>
          <div class="operate-indical operate-audit-po" @click="onlineView(row,'pdf')">结算单PDF</div>
          <template #reference>
            <el-button plain type="primary">查看</el-button>
          </template>
        </el-popover>
        <el-popover placement="bottom-start" width="130" trigger="click" v-if="hasPermission('erp:stmtAudit:list')" popper-class="indical-popover">
          <div class="operate-indical operate-audit-po" v-if="(showBtn === 1 || showBtn === 2 || showBtn === 3)" @click='downLoadEx(row)'>
            结算单excel
          </div>
          <div class="operate-indical operate-audit-po" v-if="showBtn === 3" @click='downLoadPdf(row)'>
            签章结算单PDF
          </div>
          <template #reference>
            <el-button plain type="primary">下载</el-button>
          </template>
        </el-popover>
        <el-popover placement="bottom-start" width="140" popper-class="indical-popover" v-if="row.hasAuditRecord  &&  hasPermission('erp:stmtAudit:list')" trigger="click"
      
        >
          <div class="operate-indical" @click="openLogDialog(row)">
            <span class="icon maliiconfont maliicon-3"></span>
            <span>查看审核日志</span>
          </div>
          <template #reference>
            <div class="bottom-dot"></div>
          </template>
        </el-popover>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup(props) {
          let visible = ref(false);
          const showBtn = computed(() => {
            const auditStatus = props.row.auditStatus; // "1":"待审核","2":"审核不通过","3":"已审核"
            return auditStatus;
          });
          const onlineView = (row, type) => {
            if (type === 'excel') {
              showFiles(row.excelName, row.excelUrl);
            }
            if (type === 'pdf') {
              showFiles(row.excelName, row.stmtPdfUrl);
            }
          };
          const downLoadEx = (row) => {
            showFiles(row.excelName, row.excelUrl, false);
          };
          const downLoadPdf = (row) => {
            const strFileName = row.excelName.replace(/(.*\/)*([^.]+).*/gi, '$2');
            showFiles(strFileName + '.pdf', row.stmtPdfUrl, false);
          };
          const auditASign = (row) => {
            Instance.setupState.auditASign(row);
          };
          const openLogDialog = (row) => {
            visible.value = false;
            Instance.setupState.openLogDialog(row);
          };

          return {
            visible,
            showBtn,
            onlineView,
            openLogDialog,
            auditASign,
            downLoadPdf,
            downLoadEx,
          };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
