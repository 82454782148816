<template>
  <div>
    <div class="log-item-box" v-for="(item, index) in arr" :key="index">
      <div class="title">第{{ index + 1 }}次审核</div>
      <div class="shadow-box">
        <div class="row border-bottom">
          <div class="row-50">
            <span class="color-label">结算单文件：</span
            ><span class="view" @click="viewFile(item)">查看</span>
          </div>
          <div class="row-50">
            <span class="color-label">审核状态：</span
            >{{ filterName(item.auditStatusDesc) }}
          </div>
        </div>
        <div class="row">
          <div class="row-50">
            <span class="color-label">确认人：</span
            >{{ filterName(item.confirmOperator) }}
          </div>
          <div class="row-50">
            <span class="color-label">确认时间：</span
            >{{ filterName(item.confirmTime) }}
          </div>
        </div>
        <div class="row border-bottom">
          <div class="row-100">
            <span class="color-label">确认说明：</span
            >{{ filterName(item.confirmDesc) }}
          </div>
        </div>
        <div class="row">
          <div class="row-50">
            <span class="color-label">审核人：</span
            >{{ filterName(item.auditOperator) }}
          </div>
          <div class="row-50">
            <span class="color-label">审核时间：</span
            >{{ filterName(item.auditTime) }}
          </div>
        </div>
        <div class="row">
          <div class="row-100">
            <span class="color-label">审核说明：</span
            >{{ filterName(item.auditDesc) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    arr: [Array],
    default() {
      return [];
    },
  },
  methods: {
    viewFile(row) {
      this.$showFiles(row.excelName, row.excelUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
.log-item-box {
  .color-label {
    color: #737c8c;
    font-size: 14px;
  }
  .title {
    font-size: 14px;
    color: #303133;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  .shadow-box {
    width: 600px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(35, 100, 209, 0.12);
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
  }
  .row {
    display: flex;
    justify-content: flex-start;
    color: #303133;
    font-size: 14px;
    .row-50 {
      width: 50%;
      line-height: 1;
      margin-top: 20px;
      margin-bottom: 20px;
      .view {
        color: #2878ff;
        cursor: pointer;
      }
    }
    .row-100 {
      line-height: 24px;
      padding-bottom: 16px;
    }
  }
  .border-bottom {
    border-bottom: 1px dashed #e5e9f2;
  }
}
</style>
